import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`what is Inna and its sisters? (إنّ و اخواتها)`}</h2>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`إن وأخواتها تنصب المبتدأ ويسمى اسم إن وترفع الخبر ويسمى خبر إن`}</strong></p>
    </blockquote>
    <p>{`Example
إن الطالبَ مجتهدٌ`}</p>
    <br />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`إنَّ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`التأكيد`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`أنَّ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`التأكيد`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`لكنَّ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`للاستدراك`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`زيد غني لكنه بخيل`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`كأنَّ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`للتشبيه`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`زيد كالأسد`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ليت`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`للتمني`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ليت الشبا بيعود`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When we hope for something impossible/very hard to happen`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`لعلَّ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`للترجي`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`لعل الله يغفر لي`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To hope (علَّ = لعلَّ but we mostly use لعلَّ)`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.355828220858896%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABNklEQVQoz12QiW7CMBBE8/+fU1WqOIRQP4ACgRZVUFUQSCCQw44dJz6mG44qZKWNI8/s89ge7uWc+1/bnQqDU25QlLpRn7T23KO8LgAdw1cg8O7H2J/V03537gH2bgDXdsJaC6UUGGPY/IbwV1vswxiiENBaw5Heod+g14T0aWStDWRV0dUUspwhZxxcSCjaa6qQJWkl0iwHI7BUFWk1jHWwLbDHvlc4LReQBCiDHeLpB2SSwLIciT9HEQRwdY30cwm+XsMSJPvZIKOZMs3AwwjJwocKD7eEx2Ef0XgMTcBiNkXUeyPIHtVui+D1BZfJBJpzHIc9HEYj1JRezGeIB/2rr25CDHpIyNck9RSdIqmNMah4AXlOUNN1DLW8JFC0Z0kr7z5r6H3pAHG+XH2a3rr5LylQU3/LBRoTLUFYRwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Inna and sisters",
          "title": "Inna and sisters",
          "src": "/static/82920546efa8295b1b341ee99fc594e9/a6d36/inna-and-sisters.png",
          "srcSet": ["/static/82920546efa8295b1b341ee99fc594e9/222b7/inna-and-sisters.png 163w", "/static/82920546efa8295b1b341ee99fc594e9/ff46a/inna-and-sisters.png 325w", "/static/82920546efa8295b1b341ee99fc594e9/a6d36/inna-and-sisters.png 650w", "/static/82920546efa8295b1b341ee99fc594e9/d0cc0/inna-and-sisters.png 732w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`When to use إن and when to use أن`}</h2>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`إن المكسورة تكون في`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading2"
        }}>{`أول الجملة`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`e.g. `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`إنا`}</strong>{` أنزلناه في ليلة القدر`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading2"
        }}>{`بعد القول`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`e.g. قال `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`إن`}</strong>{`ي عبد الله`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading2"
        }}>{`بعد الحلف (when we swear)`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`e.g. والله `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`إن`}</strong>{`ي أحب الخير`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`أن المفتوحة تكون في`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading2"
        }}>{`In middle of sentence`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`e.g `}
            <ul parentName="li">
              <li parentName="ul">{`يعجبني أنك مجتهد `}</li>
              <li parentName="ul">{`أشهد `}<strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`أن`}</strong>{` محمداً رسول الله`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p">{`قواعد:`}</strong><br parentName="p"></br>{`
`}{`أن المفتوحة can be used in a place where we can drop أن + Ism أن + Khabar أن + Replace it with Masdar.`}<br parentName="p"></br>{`
`}{`For example `}</p>
    <ul>
      <li parentName="ul">{`يعجبني `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أنك مجتهد`}</strong>{` can be replaced with يعجبني `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`اجتهادُك`}</strong>{`.  `}</li>
      <li parentName="ul">{`أشهد `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أن محمداً رسول الله`}</strong>{` can be converted to أشهد `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`برسالة محمد`}</strong></li>
    </ul>
    <h2>{`Rules of Order`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`خبر إن لا يأتي قبل اسم إن إلا مع شبه الجملة`}</strong></p>
    </blockquote>
    <ul>
      <li parentName="ul">{`We can't change order in normal sentence i.e. for إن الطالبَ مجتهدٌ, We can't say `}<s>{`إن مجتهدٌ الطالبَ`}</s></li>
      <li parentName="ul">{`But for Jar/Majroor and Dharf we can change order i.e. for إن طالباً في البيت we can say, إن في البيت طالباً`}
        <ul parentName="li">
          <li parentName="ul">{`Quran Examples: `}
            <ul parentName="li">
              <li parentName="ul">{`إن في ذلك لأيةً`}</li>
              <li parentName="ul">{`إن لدينا أنكالاً`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2>{`Rules of ما when used with إنّ`}</h2>
    <p>{`ما added with إنّ i.e. إنما is called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ما الكافة`}</code>{` and it stops the effect of إنّ.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`ما الكافَّة تكف ( تمنع ) إن وأخواتها عن العمل`}</strong></p>
    </blockquote>
    <br />
    <p>{`Similarly, ما can be used with rest of the sisters of إنّ`}</p>
    <br />
    <p>{`Example: إن الطالبَ مجتهدٌ when used with ما becomes إنما الطالبُ مجتهدٌ`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`إن`}</strong>{`
حرف ناسخ مبني`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`ما`}</strong>{`
الكافة حرف مبني`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الطالبُ`}</strong>{`
مبتدأ مرفوع`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`مجتهدٌ`}</strong>{`
خبر مرفوع`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p">{`قواعد:`}</strong>{` `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`إنّ`}</strong>{` : تدخل على الجملة الاسمية فقط`}</li>
      <li parentName="ul"><strong parentName="li">{`إنما`}</strong>{` : إنما تدخل على الجملة الاسمية و الفعلية`}</li>
    </ul>
    <br />
    <p>{`Some Ulema says + الحريري,`}<br parentName="p"></br>{`
`}{`we can also say,  إنما الطال`}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`بَ`}</strong>{` مجتهدٌ (here ما is not ما الكافة  but `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ما زائدة`}</code>{` )`}<br parentName="p"></br>{`
`}{`Most Ulema says above sentence is not correct but there is an exception with ليتما i.e. both of below are correct`}</p>
    <ul>
      <li parentName="ul">{`ليتما الطال`}<strong parentName="li">{`بَ`}</strong>{` مجتهدٌ`}</li>
      <li parentName="ul">{`ليتما الطال`}<strong parentName="li">{`بُ`}</strong>{` مجتهدٌ`}</li>
    </ul>
    <br />
    <h2>{`Rules with اللام المزحلقة`}</h2>
    <p>{`When إن is used with اللام المزحلقة it slips to khabar e.g.  إن الطالب لمجتهد`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`إن`}</strong>{`
حرف ناسخ مبني لا محل له من الإعراب`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الطالب`}</strong>{`
اسم إن منصوب`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`اللام`}</strong>{`
هي اللام المزحلقة حرف مبني لا محل له`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`مجتهد`}</strong>{`
خبر إن مرفوع`}</p>
    </blockquote>
    <h2>{`Reference`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.qutoofacademy.com/",
        "rel": "nofollow noopener"
      }}>{`Qutoof Academy`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      